<template>
  <div class="majorNumDetails">
    <el-scrollbar class="elScroll">
      <div class="search_sList">
        <!-- <el-tabs v-model="activeName">
          <el-tab-pane
            label="专业数详情"
            name="first"
          ></el-tab-pane>
        </el-tabs> -->
        <div class="top_opera">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            label-width="80px"
          >
            <el-form-item label="层次">
              <el-select v-model="formInline.eduCategory" placeholder="层次" clearable>
                <el-option v-for="(item, key, index) in $common.getEduCategory" :key="index" :label="item" :value="key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业名称">
              <el-input
                v-model="formInline.majorName"
                placeholder="专业名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="学科名称">
              <el-input
                v-model="formInline.subjectName"
                placeholder="学科名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="目录名称">
              <el-input
                v-model="formInline.directoryName"
                placeholder="目录名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button
              class="mainBtn"
              @click="onSubmit"
              plain
              v-preventReClick
              >搜索</el-button
            >
            <el-button type="info" @click="resetEvent" plain>重置</el-button>
          </div>
        </div>
      </div>
      <div class="table_sList">
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          align="center"
        >
          <el-table-column
            prop="eduCategory"
            label="层次"
            align="center"
            width="100"
            :formatter="regEduCategory"
          >
          </el-table-column>
          <el-table-column
            label="专业名称"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.majorName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.majorName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="学科名称"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.subjectName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.subjectName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="目录名称"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.directoryName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.directoryName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          @changePage="changePage"
          :childMsg="childMsg"
        ></Pagination>
      </div>
    </el-scrollbar>
    <Keydown @keydownEvent='handleKeydown'></Keydown>
  </div>
</template>

<script>
import Pagination from '../../Pagination'
export default {
  name: 'majorNumDetails',
  components: {
    Pagination
  },
  data() {
    return {
      activeName: 'first',
      // subjectName: [],
      // catalogueName: [],
      formInline: {
        directoryName: null,
        eduCategory: null,
        majorName: null,
        page: 1,
        pageSize: 10,
        subjectName: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
    }
  },
  created() {
    let query = this.$route.query;
    this.formInline.eduCategory = query.eduCategory
    this.formInline.directoryName = query.directoryName
    this.getlibData();
  },
  methods: {
    handleKeydown(event) {
      this.onSubmit()
    },
    // 搜索
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getlibData();
    },
    // 重置
    resetEvent() {
      this.formInline.directoryName = null
      this.formInline.eduCategory = null
      this.formInline.majorName = null
      this.formInline.page = 1
      this.formInline.pageSize = 10
      this.formInline.subjectName = null
    },
    // 获取专业库表格数据
    getlibData() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getlibData(obj, (res) => {
        this.loading = false;
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total,
          };
        }
      });
    },
    regEduCategory(row) {
      let edu = String(row.eduCategory);
      let val = this.$common.getEduCategory[edu];
      return val;
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getlibData();
    },
  }
}
</script>

<style lang='less'>
  .majorNumDetails {
    height: 100%;
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
</style>